// external imports
import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { makeStyles } from '@material-ui/styles';
import { Link } from "react-router-dom";

// internal imports
import { COLOR_PALETTE } from "./Constants";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  title: {
    flexGrow: 1,
    color: 'black'
  },
  tagLine: {
    color: COLOR_PALETTE["tertary"],
  },
  navLinks: {
    paddingLeft: '25px'
  },
  homeLink: {
    textDecoration: 'none',
    color: 'black',
  },
  appBar2: {
    backgroundColor: "#fff"
  }
}));


const theme = createTheme({
  status: {
    danger: 'rgb(100,100,100)',
  },
  palette: {
    secondary: {
      main: "#fff",
      contrastText: "#6a0dad"
    }
  }
});

export default function Navbar() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ThemeProvider theme={theme}>
      <AppBar position="static" color="primary" elevation={0} style={{ 
        background: COLOR_PALETTE["primary"],
        }}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            <Link className={classes.homeLink} style={{
              'color': COLOR_PALETTE["secondary"]
            }} to='/'>improvta </Link> 
            <span className={classes.tagLine}>cryptocurrency analytics</span>
          </Typography>
        </Toolbar>
      </AppBar>
      </ThemeProvider>
    </div>
  );
}