// external imports
import styled from 'styled-components';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

// internal imports
import './App.css';
import { COLOR_PALETTE } from "./common/Constants"
import { LogoArt } from "./common/LogoArt"
import Navbar from './common/Navbar.js';

const MainContent = styled(Paper)`
  margin: 0 auto;
  text-align: left;
  width: 50%;
  margin-top: 50px;
  background-color: rgba(255,255,255,0);
  display: grid;
  grid-template-columns: 50% 50%;
  column-gap: 100px;
  height: 50vh;
  justify-items: center;
  align-items: center;
  min-width: 300px;
  padding: 50px;
`;

const TaglineHeader = styled.h2`
  color: ${COLOR_PALETTE["secondary"]};
`

const Summary = styled.div`
  margin-right: 50px;
  display: grid;
  grid-template-rows: 50px 100px 100px;
  row-gap: 20px;
  min-width: 300px;
`

const TaglineSummary = styled.span`
  margin-bottom: 50px;
`

const ArtPanel = styled.div`
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  opacity: 1;
  text-align: center;
  margin-left: 50px;
`

function App() {
  return (
    <Router>
      <div>
        <Navbar/>
        <Switch>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

function Home() {
  return (
  <MainContent elevation={0}>
    <Summary>
      <TaglineHeader>
        Improvising with Data
      </TaglineHeader>
      <TaglineSummary>
        <p>
          We believe in a creative form of data analytics that tells a story.
        </p>
        <p>
          Our stories enable powerful narratives for disruptive innovation. 
        </p>
      </TaglineSummary>
      <Link
          to='#'
          style={{ textDecoration: 'none', marginTop: '25px' }}
          onClick={(e) => {
              window.location.href = "mailto:nemo@improvta.com";
              e.preventDefault();
          }}
      >
        <Button variant="contained" 
          style={{backgroundColor: COLOR_PALETTE["secondary"]}}>
          Contact
        </Button>
      </Link>

    </Summary>
    <ArtPanel>
      <LogoArt></LogoArt>
    </ArtPanel>
  </MainContent>
  )
}

export default App;