// external imports
import React from 'react';
import gsap from "gsap";

// internal import
import { COLOR_PALETTE } from './Constants';

export const LogoArt = () => {
    const boxRef = React.useRef();

    React.useEffect(() => {
        gsap.to([boxRef.current], {
          scale: 1.3,
          duration: 2,
          repeat: -1,
          yoyo: true,
          yoyoEase: 'expoScale(1, 2, expo.in)'
        });
      });

    return (
      <div
        ref={boxRef}
        style={{
          width: "160px",
          height: "160px",
          borderRadius: "50%",
          border: `2px solid ${COLOR_PALETTE["secondary"]}`,
        }}
      />
    );
  };